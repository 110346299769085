import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';
/****Layouts*****/

const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));
/***** Pages ****/
const Modern = Loadable(lazy(() => import('../views/dashboards/Modern')));
const Profile = Loadable(lazy(() => import('../views/sample-pages/Profile')));
const ResetPassword = Loadable(lazy(() => import('../views/password/ResetPassword')));
const BlogList = Loadable(lazy(() => import('../views/blog/BlogList')));
const AddBlog = Loadable(lazy(() => import('../views/blog/AddBlog')));
const EditBlog = Loadable(lazy(() => import('../views/blog/EditBlog')));
const GalleryList = Loadable(lazy(() => import('../views/gallery/GalleryList')));
const AddGallery = Loadable(lazy(() => import('../views/gallery/AddGallery')));
const EditGallery = Loadable(lazy(() => import('../views/gallery/EditGallery')));
const ContactList = Loadable(lazy(() => import('../views/contact/ContactList')));
const LoginFormik = Loadable(lazy(() => import('../views/auth/LoginFormik')));
/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../views/auth/Error')));
/*****Routes******/
const ThemeRoutes = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', name: 'Home', element: <Navigate to="/dashboard" /> },
      { path: '/dashboard', name: 'Modern', exact: true, element: <Modern /> },
      { path: '/reset-password', name: 'ResetPassword', exact: true, element: <ResetPassword /> },
      { path: '/profile', name: 'profile', exact: true, element: <Profile /> },
      { path: '/contact/contact-list', name: 'ContactList', exact: true, element: <ContactList /> },
      { path: '/blog/blog-list', name: 'BlogList', exact: true, element: <BlogList /> },
      {
        path: '/blog/add-blog',
        name: 'AddBlog',
        exact: true,
        element: <AddBlog />,
      },
      {
        path: '/blog/edit-blog/:blogId',
        name: 'EditBlog',
        exact: true,
        element: <EditBlog />,
      },
      { path: '/gallery/gallery-list', name: 'GalleryList', exact: true, element: <GalleryList /> },
      {
        path: '/gallery/add-gallery',
        name: 'AddGallery',
        exact: true,
        element: <AddGallery />,
      },
      {
        path: '/gallery/edit-gallery/:galleryId',
        name: 'EditGallery',
        exact: true,
        element: <EditGallery />,
      },

      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: 'login', element: <LoginFormik /> },
    ],
  },
];

export default ThemeRoutes;
